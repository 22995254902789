body {
  margin: 0;
  font-family: 'Roboto Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background: #1d1f24;
}

body::-webkit-scrollbar-thumb {
  background-color: #3bc47f;
  border-radius: 20px;
}

code {
  background-color: #1d1f24;
  color: #3bc47f;
  border-radius: 4px;
  padding: 2px 4px;
}

a {
  color: #3bc47f;
}
